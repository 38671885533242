@import 'normalize';

@import 'variebles';

@import 'fonts';
@import 'responce';
@import 'header';
@import 'home';
@import 'footer';

body {
    overflow-x: hidden;
}

.container {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
}

.section-title {
    font-family: $fontFuturaBold;
    font-size: 40px;
    line-height: 50px;
    color: #6F54DD;
    text-shadow: 0px 4px 10px rgba(136, 107, 252, 0.25);
    text-align: center;
    margin-bottom: 40px;
    position: relative;
    &::after {
        content: " ";
        display: block;
        width: 140px;
        height: 1px;
        background: #C4C4C4;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -20px;
    }
    @include mediaw(480px) {
        font-size: 27px;    
        line-height: 35px;
        margin-bottom: 35px;
    }
    span {
        display: block;
        text-transform: uppercase;
    }
}

.price__row {
    display: inline-flex;
    align-items: center;
    background: #F1F4FA;
    border-radius: 17px;
    padding: 35px 50px;
    margin: 60px 0 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    @include mediaw(576px) {
        padding: 25px 10px;
    }
    @include mediaw(601px) {
        justify-content: space-around;
        width: 100%;
    }
    @include mediaw(375px) {
        justify-content: space-between;
    }
    &-block {
        color: #000;
        display: inline-block;
        .new {
            font-family: $fontPTSansRegular;
            font-size: 16px;
            line-height: 22px;
            span {
                display: block;
                margin-top: 10px;
                font-size: 30px;
                line-height: 38px;
                color: #000;
                @include mediaw(576px) {

                }
            }
        }
    }
    .price__order {
        display: inline-block;
        span {
            display: inline-block;
            margin-left: 90px;
            padding: 21px 60px;
            color: #fff;
            font-family: $fontPTSansRegular;
            font-size: 20px;
            line-height: 27px;
            background: #886BFC;
            box-shadow: 0px 4px 15px rgba(136, 107, 252, 0.4);
            border-radius: 73px;
            @include mediaw(601px) {
                margin-left: 0;
            }
            @include mediaw(576px) {
                font-size: 20px;
                padding: 15px 10px;
            }
            @include mediaw(345px) {
                font-size: 18px;
            }
        }
    }
}