.how {
    padding-top: 100px;
    background-color: #fff;
    @include mediaw(576px) {
        padding-top: 70px;
    }
    &__row {
        display: flex;
        @include mediaw(768px) {
            display: block;
        }
        .left, .right {
            width: 50%;
            padding: 0 30px;
            .how__title {
                @include mediaw(768px) {
                    text-align: center;
                }
            }
            @include mediaw(768px) {
                padding: 0;
                width: 100%;
            }
        }
        .right {
            margin-top: 20px;
            @include mediaw(768px) {
                margin-top: 14px;
            }
        }
        .left, .right {
            .image-block {
                text-align: center;
                img {
                    max-width: 100%;
                }
            }
        }
        .left {
            .image-block img {
                width: 350px;
            }
            .how__title {
                margin-top: 50px;
                @include mediaw(768px) {
                    margin-top: 30px;
                }
            }
        }
        .right {
            .image-block img {
                width: 310px;
            }
            .how__title {
                margin-top: 70px;
                @include mediaw(768px) {
                    margin-top: 30px;
                }
            }
        }
    }
    &__title {
        font-size: 32px;
        line-height: 41px;
        color: #6F54DD;
        text-shadow: 0px 4px 10px rgba(136, 107, 252, 0.25);
        font-family: $fontFuturaBold;
        @include mediaw(576px) {
            font-size: 22px;
            line-height: 29px;
        }
    }
    &__list {
        margin-top: 34px;
        font-size: 20px;
        line-height: 27px;
        color: #000000;
        font-family: $fontPTSansRegular;
        padding-left: 45px;
        @include mediaw(768px) {
            margin-top: 15px;
        }
        @include mediaw(576px) {
            font-size: 14px;
            line-height: 19px;
            padding-left: 32px;
        }
        li {
            position: relative;
            &::before {
                content: " ";
                display: block;
                width: 30px;
                height: 30px;
                background-image: url(../img/plus.svg);
                background-size: cover;
                position: absolute;
                top: -1px;
                left: -45px;
                @include mediaw(576px) {
                    left: -32px;
                    width: 21px;
                    height: 21px;
                }
                
            }
            &:not(:first-child) {
                margin-top: 10px;
                @include mediaw(576px) {
                    margin-top: 7px;
                }
            }
        }
        &.minus {
            li::before {
                background-image: url(../img/minus.svg);
            }
        }
    }
}

.description {
    padding: 100px 0 50px;
    @include mediaw(768px) {
        padding: 50px 0 25px;
    }
    &__row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 60px;
        @include mediaw(1092px) {
            justify-content: space-around;
        }
        @include mediaw(600px) {
            justify-content: space-between;
        }
        @include mediaw(576px) {
            margin-top: 28px;
        }
    }
    &__item {
        width: calc(33.3334% - 10px);
        @include mediaw(992px) {
            width: calc(50% - 10px);
        }
        .image-block {
            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
                border-radius: 50%;
                margin: 0 auto;
            }
        }
        .text {
            text-align: center;
            font-family: $fontPTSansRegular;
            font-size: 20px;
            line-height: 30px;
            color: #404040;
            margin: 25px 0 30px;
            @include mediaw(480px) {
                margin-top: 5px;
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
    .price__row {
        @include mediaw(576px) {
            margin-top: 0;
        }
    }
}

.information {
    font-family: $fontPTSansRegular;
    font-size: 18px;
    line-height: 29px;
    color: #404040;
    padding-bottom: 100px;
    @include mediaw(768px) {
        font-size: 18px;
        line-height: 26px;
        padding: 25px 0;
    }
    @include mediaw(480px) {
        padding: 25px 0;
        font-size: 15px;
        line-height: 20px;
    }
    &__title {
        margin-bottom: 50px !important;
        @include mediaw(768px) {
            margin-bottom: 35px !important;
        }
    }
    .desctop {
        @include mediaw(768px) {
            display: none;
        }
    }
    .mobile {
        display: none !important;
        @include mediaw(768px) {
            display: block !important;
        }
    }
}

.look {
    padding: 40px 0;
    @include mediaw(480px) {
        padding: 20px 0;
        margin-bottom: 0;
    }
    &__title {
        margin-bottom: 58px !important;
        @include mediaw(480px) {
            margin-bottom: 41px !important;
        }
    }
    &__video {
        width: 850px;
        margin: 0 auto;
        height: 480px;
        @include mediaw(900px) {
            width: 100%;
        }
        @include mediaw(685px) {
            height: 360px;
        }
        @include mediaw(525px) {
            height: 205px;
        }
        iframe {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .price__row {
        margin-top: 40px;
        @include mediaw(480px) {
            margin-top: 28px;
        }
    }
}

.reviews {
    padding: 80px 0;
    background-color: #fff;
    @include mediaw(768px) {
        padding: 60px 0;
    }
    @include mediaw(480px) {
        padding: 35px 0;
    }
    &__slider--wrapper {
        position: relative;
        @include mediaw(1300px) {
            padding: 0 45px;
        }
        @include mediaw(480px) {
            padding: 0 20px;
        }
        .reviews__buttons {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -60px;
            width: calc(100% + 120px);
            display: flex;
            justify-content: space-between;
            z-index: 1;
            @include mediaw(1300px) {
                left: -10px;
                width: calc(100% + 20px);
            }
            @include mediaw(480px) {
                left: -5px;
                width: calc(100% + 10px);
            }
            .reviews__buttons-prev, .reviews__buttons-next {
                width: 30px;
                height: 50px;
                background-repeat: no-repeat;
                background-size: cover;
                cursor: pointer;
                @include mediaw(480px) {
                    width: 15px;
                    height: 25px;
                }
            }
            .reviews__buttons-prev {
                background-image: url(../img/arrow-left.svg);
            }
            .reviews__buttons-next {
                background-image: url(../img/arrow-right.svg);
            }
        }
    }
    &__slider {
        z-index: 1;
    }
    .slick-list {
        margin: 0 -15px;
    }
    .slick-dots {
        bottom: -35px;
        li {
            width: 15px;
            height: 15px;
            margin: 0 10px;
            @include mediaw(480px) {
                margin: 0 5px;
            }
            button {
                width: 15px;
                height: 15px;
                &::before {
                    font-size: 15px;
                    width: 15px;
                    height: 15px;
                    color: #F1F4FA;
                    opacity: 1;
                    @include mediaw(480px) {
                        font-size: 13px;
                    }
                }
            }
            &.slick-active {
                button {
                    &::before {
                        color: #886BFC;
                    }
                }
            }
        }
    }
    &__slide {
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        border-radius: 21px;
        margin: 100px 15px 20px;
        padding: 100px 0 40px;
        position: relative;
        .image-block {
            width: 200px;
            height: 200px;
            position: absolute;
            top: -100px;
            left: 50%;
            transform: translateX(-50%);
            overflow: hidden;
            border-radius: 50%;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .name, .text {
            padding: 0 30px;
            @include mediaw(480px) {
                padding: 0 15px;
            }
        }
        .name {
            margin-top: 35px;
            color: #172134;
            font-family: $fontPTSansBold;
            font-size: 28px;
            line-height: 40px;
            text-align: center;
            @include mediaw(480px) {
                margin-top: 15px;
                font-size: 24px;
                line-height: 36px;
            }
        }
        .text {
            margin-top: 20px;
            font-family: $fontPTSansRegular;
            font-size: 18px;
            line-height: 24px;
            color: #404040;
            text-align: center;
            @include mediaw(480px) {
                margin-top: 10px;
                font-size: 14px;
            }
        }
    }
    .price__row {
        margin-top: 75px;
    }
    .price__row-block {
        border-color: #eaeaea;
        color: #606060;
        span {
            color: #212121;
        }
        &::after {
            background-color: #eaeaea;
        }
    }
}

.characteristic {
    background-color: #fff;
    padding: 95px 0 25px;
    @include mediaw(480px) {
        padding: 50px 0;
    }
    &__title {
        margin-bottom: 58px !important;
        @include mediaw(480px) {
            margin-bottom: 41px;
        }
    }
    .list-row {
        display: flex;
        justify-content: center;
        align-items: center;
        @include mediaw(991px) {
            flex-wrap: wrap;
        }
    }
    &__list {
        width: calc(50% - 20px);
        font-family: $fontPTSansRegular;
        font-size: 18px;
        line-height: 22px;
        color: #404040;
        @include mediaw(991px) {
            width: 100%;
        }
        @include mediaw(480px) {
            font-size: 14px;
            line-height: 20px;
        }
        li {
            display: flex;
            justify-content: space-between;
            padding: 4px 15px;
            background-color: #f0f3f8;
            &:nth-child(2n) {
                background-color: #fff;
            }
            p:first-child {
                width: 50%;
            }
            p:last-child {
                width: 50%;
                text-align: right;
            }
            &.warning {
                color: #c73540;
                p:first-child {
                    width: 0;
                }
                p:last-child {
                    width: 100%;
                }
            }
        }
    }
    .image-block {
        @include mediaw(992px) {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 25px;
        }
        @include mediaw(480px) {
            margin-top: 15px;
        }
        img {
            max-width: 100%;
        }
    }
    .price__row {
        margin-top: 30px;
    }
}

.images {
    &__row {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    &__item {
        width: 33.33334%;
        max-height: 380px;
        @include mediaw(768px) {
            width: 50%;
        }
        @include mediaw(576px) {
            max-height: 266px;
        }
        img {
            display: block;
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
            object-fit: cover;
        }
    }
}

.form {
    background-repeat: no-repeat;
    background-image: url(../img/form-bg.jpg);
    background-size: cover;
    background-color: #0b080d;
    color: #fff;
    padding: 35px 0;
    min-height: 768px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    @include mediaw(888px) {
        background-position-x: -140px;
    }
    @include mediaw(768px) {
        background-position-x: -690px;
    }
    @include mediaw(576px) {
        min-height: initial;
        background-color: #fff;
        background-image: none;
    }
    &::before {
        content: " ";
        display: block;
        width: 900px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, #FFFFFF 100%);
        z-index: -1;
        @include mediaw(1199px) {
            display: none;
        }
    }
    .stamp {
        content: " ";
        display: block;
        width: 294px;
        height: 250px;
        background-image: url(../img/stamp.png);
        background-size: cover;
        position: absolute;
        top: 0;
        left: 45%;
        transform: translateY(10px) translateX(-10px);
        opacity: 0;
        transition: opacity .1s, transform .1s;
        transition-delay: 2s;
        z-index: 2;
        @include mediaw(1410px) {
            left: 40%;
        }
        @include mediaw(1190px) {
            left: 35%;
        }
        @include mediaw(1190px) {
            left: 400px;
            top: 45px;
        }
        @include mediaw(1050px) {
            left: 325px;
            top: 275px;
            width: 206px;
            height: 175px;
        }
        @include mediaw(900px) {
            left: 425px;
            top: 0;
        }
        @include mediaw(888px) {
            left: 325px;
        }
        @include mediaw(768px) {
            top: -5px;
            left: 50%;
            transform: translateX(-50%) !important;
        }
        @include mediaw(576px) {
            width: 103px;
            height: 87.5px;
        }
        @include mediaw(480px) {
            top: -20px;
        }
        &.active {
            transform: translateY(0) translateX(-0);
            opacity: 1;
        }
    }
    &__block {
        border-radius: 10px;
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        padding: 35px 30px 30px;
        max-width: 360px;
        margin-left: auto;
        @include mediaw(768px) {
            margin: 0 auto;
        }
        @include mediaw(480px) {
            padding: 17.5px 15px 20px;
        }
    }
    &__title {
        font-size: 32px !important;
        margin-bottom: 0 !important;
        line-height: 41px !important;
        @include mediaw(576px) {
            font-size: 30px!important;
        }
        @include mediaw(350px) {
            font-size: 28px!important;
        }
        &::after {
            display: none !important;
        }
    }
    .container {
        position: relative;
        z-index: 1;
    }
    form {
        display: block;
        .input-wrapper {
            position: relative;
            width: 100%;
            &:last-child {
                @include mediaw(480px) {
                    margin-top: 15px;
                }
            }
        }
        .input-wrapper .floating-label {
            position: absolute;
            pointer-events: none;
            top: 30px;
            left: 0;
            transition: 0.2s ease all;
            font-family: $fontPTSansRegular;
            font-size: 18px;
            color: #212121;
            @include mediaw(480px) {
                font-size: 14px;
                line-height: 38px;
            }
        }
        .input-wrapper input:focus ~ .floating-label,
        .input-wrapper input:not(:focus):valid ~ .floating-label{
            top: 0;
            font-size: 14px;
        }
        .inputs-wrapper {
            display: flex;
            justify-content: space-between;
            @include mediaw(480px) {
                flex-wrap: wrap;
            }
        }
        input {
            display: block;
            width: 100%;
            &:not([type="submit"]) {
                height: 70px;
                background-color: transparent;
                border-bottom: 1px solid #212121;
                font-size: 25px;
                line-height: 40px;
                color: #212121;
                padding-top: 25px;
                @include mediaw(480px) {
                    padding-top: 15px;
                    height: 75px;
                }
            }
            &[type="submit"] {
                width: 260px;
                height: 70px;
                background-color: #886BFC;
                box-shadow: 0px 4px 15px rgba(136, 107, 252, 0.4);
                border-radius: 73px;
                font-family: $fontPTSansRegular;
                font-size: 20px;
                color: #fff;
                cursor: pointer;
                margin: 30px auto 0;
                @include mediaw(600px) {
                    margin: 15px auto 0;
                }
                @include mediaw(341px) {
                    max-width: 100%;
                }
            }
            &[type="radio"] {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }
        }
        .color--wrapper {
            font-family: $fontPTSansRegular;
            font-size: 18px;
            line-height: 40px;
            color: #212121;
            margin: 15px auto 0;
            text-align: center;
            max-width: 222px;
            @include mediaw(480px) {
                font-size: 13px;
                line-height: 20px;
            }
            div {
                display: flex;
                justify-content: center;
                margin-top: 10px;
            }
            .color-item {
                display: block;
                width: 68px;
                height: 68px;
                border-radius: 50%;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
                cursor: pointer;
                &:not(:first-child) {
                    margin-left: 10px;
                }
                &-name {
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-size: 13px;
                    background-color: #fff;
                    top: 0px;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    border: 1px solid #000;
                    line-height: 14px;
                    padding: 3px;
                    border-radius: 10px;
                    opacity: 0;
                    transition: opacity .3s;
                    &::after {
                        content: " ";
                        display: block;
                        width: 7px;
                        height: 7px;
                        position: absolute;
                        left: 50%;
                        bottom: -5px;
                        transform: translateX(-50%) rotate(-45deg);
                        border-left: 1px solid #000;
                        border-bottom: 1px solid #000;
                        background-color: #fff;
                    }
                }
                &:hover .color-item-name {
                    opacity: 1;
                }
                @include mediaw(480px) {
                    padding: 5px 0 5px 30px;
                }
                @include mediaw(480px) {
                    width: 50px;
                    height: 50px;
                }
                &:first-child {
                    background-color: #eaeaea;
                }
                &:nth-child(2) {
                    background-color: #3e3e3e;
                }
                input:checked ~ .radioBtn {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    width: 26px;
                    height: 20px;
                    background-image: url(../img/checked.svg);
                    background-size: cover;
                }
            }
        }
    }
    &__price {
        display: flex;
        justify-content: center;
        font-family: $fontPTSansRegular;
        font-size: 16px;
        line-height: 24px;
        position: relative;
        left: -30px;
        color: #606060;
        width: calc(100% + 60px);
        padding: 15px 20px;
        text-align: center;
        @include mediaw(768px) {
            margin: 20px auto 0;
        }
        @include mediaw(480px) {
            font-size: 14px;
            line-height: 22px;
            left: -15px;
            width: calc(100% + 30px);
            padding: 10px 25px;
        }
        .old, .new {
            span {
                font-size: 28px;
                line-height: 40px;
                display: inline-block;
                color: #181f2f;
                margin-top: 20px;
                @include mediaw(480px) {
                    margin-top: 10px;
                }
                @include mediaw(374px) {
                    font-size: 29px;
                    line-height: initial;
                }
            }
        }
        .new {
            text-align: right;
            span {
                font-family: $fontPTSansBold;
                @include mediaw(341px) {
                    font-size: 26px;
                }
            }
        }
    }
}

.advantages {
    background-color: #2d3c5a;
    color: #fff;
    padding: 40px 0;
    .container {
        display: flex;
        justify-content: space-between;
        @include mediaw(768px) {
            flex-wrap: wrap;
        }
    }
    &__item {
        width: calc(33.33334% - 30px);
        padding-left: 120px;
        position: relative;
        background-repeat: no-repeat;
        @include mediaw(768px) {
            width: 100%;
            padding-left: 90px;
        }
        &:first-child {
            background-image: url(../img/delivery-icon.svg);
            background-position: 20px 35px;
            @include mediaw(768px) {
                background-position: 12px 23px;
                background-size: 55px;
            }
        }
        &:nth-child(2) {
            background-image: url(../img/wallet-icon.svg);
            background-position: 27px 23px;
            @include mediaw(768px) {
                background-position: 17px 13px;
                background-size: 44px;
                margin-top: 20px;
            }
        }
        &:last-child {
            background-image: url(../img/package-icon.svg);
            background-position: 32px 28px;
            @include mediaw(768px) {
                background-position: 20px 16px;
                margin-top: 20px;
            }
        }
        &::before {
            content: " ";
            display: block;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border: solid 2px rgba(255, 255, 255, 0.4);
            position: absolute;
            left: 0;
            top: 0;
            @include mediaw(768px) {
                width: 75px;
                height: 75px;
            }
        }
        &-title {
            font-family: $fontPTSansBold;
            font-size: 24px;
            line-height: 40px;
            @include mediaw(480px) {
                font-size: 21px;
                line-height: 38px;
            }
        }
        &-description {
            margin-top: 10px;
            font-family: $fontPTSansRegular;
            font-size: 18px;
            line-height: 22px;
            @include mediaw(480px) {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}