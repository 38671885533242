*, *::before, *::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }

a {
  color: inherit;
  text-decoration: none; }

ul {
  list-style: none; }

input, button {
  border: none; }
  input:focus, button:focus {
    outline: none; }

body.overflow-active {
  overflow: hidden; }

@font-face {
  font-family: 'PT Sans Regular';
  src: url("../fonts/PTSans-Regular.eot");
  src: url("../fonts/PTSans-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/PTSans-Regular.woff") format("woff"), url("../fonts/PTSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'PT Sans Bold';
  src: url("../fonts/PTSans-Bold.eot");
  src: url("../fonts/PTSans-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/PTSans-Bold.woff") format("woff"), url("../fonts/PTSans-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT Bold';
  src: url("../fonts/FuturaPT-Bold.eot");
  src: url("../fonts/FuturaPT-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-Bold.woff") format("woff"), url("../fonts/FuturaPT-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT Book';
  src: url("../fonts/FuturaPT-Book.eot");
  src: url("../fonts/FuturaPT-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-Book.woff") format("woff"), url("../fonts/FuturaPT-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.header {
  background-repeat: no-repeat;
  background-image: url(../img/header-bg.jpg);
  background-color: #0B080D;
  background-position-x: right;
  background-size: cover;
  height: 768px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  z-index: 1; }
  @media only screen and (max-width: 992px) {
    .header {
      background-position-x: calc(100% + 135px); } }
  @media only screen and (max-width: 768px) {
    .header {
      background: #fffaf5;
      height: initial;
      padding-bottom: 20px; } }
  .header::before {
    content: " ";
    display: block;
    width: 900px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: -webkit-gradient(linear, left top, right top, from(#FFFFFF), color-stop(50%, white), to(rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(left, #FFFFFF 0%, white 50%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(left, #FFFFFF 0%, white 50%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(90deg, #FFFFFF 0%, white 50%, rgba(255, 255, 255, 0) 100%);
    z-index: -1; }
    @media only screen and (max-width: 1199px) {
      .header::before {
        width: 630px; } }
    @media only screen and (max-width: 768px) {
      .header::before {
        display: none; } }
  .header .stamp {
    content: " ";
    display: block;
    width: 294px;
    height: 250px;
    background-image: url(../img/stamp.png);
    background-size: cover;
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateY(10px) translateX(-10px);
        -ms-transform: translateY(10px) translateX(-10px);
            transform: translateY(10px) translateX(-10px);
    opacity: 0;
    -webkit-transition: opacity .1s, -webkit-transform .1s;
    transition: opacity .1s, -webkit-transform .1s;
    -o-transition: opacity .1s, transform .1s;
    transition: opacity .1s, transform .1s;
    transition: opacity .1s, transform .1s, -webkit-transform .1s;
    -webkit-transition-delay: 2s;
         -o-transition-delay: 2s;
            transition-delay: 2s;
    z-index: 2; }
    @media only screen and (max-width: 1410px) {
      .header .stamp {
        left: 40%; } }
    @media only screen and (max-width: 1190px) {
      .header .stamp {
        left: 35%; } }
    @media only screen and (max-width: 1190px) {
      .header .stamp {
        left: 400px;
        top: 45px; } }
    @media only screen and (max-width: 1075px) {
      .header .stamp {
        left: 375px; } }
    @media only screen and (max-width: 1040px) {
      .header .stamp {
        top: 95px;
        width: 206px;
        height: 175px; } }
    @media only screen and (max-width: 960px) {
      .header .stamp {
        left: 330px; } }
    @media only screen and (max-width: 768px) {
      .header .stamp {
        display: none; } }
    .header .stamp.active {
      -webkit-transform: translateY(0) translateX(0);
          -ms-transform: translateY(0) translateX(0);
              transform: translateY(0) translateX(0);
      opacity: 1; }
  .header__description {
    font-family: "PT Sans Regular";
    font-size: 22px;
    line-height: 30px;
    color: #606060; }
    @media only screen and (max-width: 768px) {
      .header__description {
        display: none; } }
  .header__title {
    font-family: "Futura PT Bold";
    color: #6F54DD;
    text-shadow: 0px 4px 10px rgba(136, 107, 252, 0.25);
    margin-top: 30px; }
    .header__title span {
      display: block; }
    .header__title .small {
      font-size: 24px;
      line-height: 31px;
      margin-top: 10px; }
    .header__title .medium {
      font-size: 29px;
      line-height: 37px; }
    .header__title .big {
      font-size: 52px;
      line-height: 67px; }
    @media only screen and (max-width: 768px) {
      .header__title {
        display: none; } }
  .header .image-block {
    display: none;
    width: calc(100% + 30px);
    -webkit-transform: translateX(-15px);
        -ms-transform: translateX(-15px);
            transform: translateX(-15px); }
    @media only screen and (max-width: 768px) {
      .header .image-block.tablet {
        display: block; } }
    .header .image-block img {
      display: block;
      margin: 0 auto;
      max-width: 100%; }
  .header__list {
    margin-top: 30px;
    font-family: "PT Sans Regular";
    font-size: 18px;
    line-height: 25px;
    color: #212121; }
    @media only screen and (max-width: 768px) {
      .header__list {
        font-size: 14px;
        line-height: 38px;
        margin: 10px auto 0;
        max-width: 330px; } }
    @media only screen and (max-width: 370px) {
      .header__list {
        font-size: 12px; } }
    .header__list li {
      padding: 5px 0 5px 40px;
      background-repeat: no-repeat;
      background-position: left center;
      margin-top: 10px; }
      @media only screen and (max-width: 576px) {
        .header__list li {
          margin-top: 0; } }
      .header__list li:first-child {
        background-image: url(../img/list-icon1.svg); }
      .header__list li:nth-child(2) {
        background-image: url(../img/list-icon2.svg); }
      .header__list li:last-child {
        background-image: url(../img/list-icon3.svg); }
  .header__price {
    margin-top: 53px;
    padding: 30px;
    display: inline-block;
    font-family: "PT Sans Regular";
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #000;
    background: rgba(247, 245, 255, 0.6);
    border: 1px solid #E5DEFF;
    border-radius: 17px; }
    @media only screen and (max-width: 768px) {
      .header__price {
        margin: 20px auto 0;
        position: relative;
        left: 50%;
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%); } }
    @media only screen and (max-width: 576px) {
      .header__price {
        padding: 21px; } }
    .header__price .new span {
      font-family: "Futura PT Book";
      font-size: 30px;
      line-height: 38px;
      -webkit-transition: color .3s;
      -o-transition: color .3s;
      transition: color .3s; }
      @media only screen and (max-width: 351px) {
        .header__price .new span {
          font-size: 31px; } }
  .header__order {
    margin-top: 20px;
    width: 260px;
    height: 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-family: "PT Sans Regular";
    font-size: 20px;
    line-height: 27px;
    color: #fff;
    background: #886BFC;
    -webkit-box-shadow: 0px 4px 15px rgba(136, 107, 252, 0.4);
            box-shadow: 0px 4px 15px rgba(136, 107, 252, 0.4);
    border-radius: 73px; }

.how {
  padding-top: 100px;
  background-color: #fff; }
  @media only screen and (max-width: 576px) {
    .how {
      padding-top: 70px; } }
  .how__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    @media only screen and (max-width: 768px) {
      .how__row {
        display: block; } }
    .how__row .left, .how__row .right {
      width: 50%;
      padding: 0 30px; }
      @media only screen and (max-width: 768px) {
        .how__row .left .how__title, .how__row .right .how__title {
          text-align: center; } }
      @media only screen and (max-width: 768px) {
        .how__row .left, .how__row .right {
          padding: 0;
          width: 100%; } }
    .how__row .right {
      margin-top: 20px; }
      @media only screen and (max-width: 768px) {
        .how__row .right {
          margin-top: 14px; } }
    .how__row .left .image-block, .how__row .right .image-block {
      text-align: center; }
      .how__row .left .image-block img, .how__row .right .image-block img {
        max-width: 100%; }
    .how__row .left .image-block img {
      width: 350px; }
    .how__row .left .how__title {
      margin-top: 50px; }
      @media only screen and (max-width: 768px) {
        .how__row .left .how__title {
          margin-top: 30px; } }
    .how__row .right .image-block img {
      width: 310px; }
    .how__row .right .how__title {
      margin-top: 70px; }
      @media only screen and (max-width: 768px) {
        .how__row .right .how__title {
          margin-top: 30px; } }
  .how__title {
    font-size: 32px;
    line-height: 41px;
    color: #6F54DD;
    text-shadow: 0px 4px 10px rgba(136, 107, 252, 0.25);
    font-family: "Futura PT Bold"; }
    @media only screen and (max-width: 576px) {
      .how__title {
        font-size: 22px;
        line-height: 29px; } }
  .how__list {
    margin-top: 34px;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
    font-family: "PT Sans Regular";
    padding-left: 45px; }
    @media only screen and (max-width: 768px) {
      .how__list {
        margin-top: 15px; } }
    @media only screen and (max-width: 576px) {
      .how__list {
        font-size: 14px;
        line-height: 19px;
        padding-left: 32px; } }
    .how__list li {
      position: relative; }
      .how__list li::before {
        content: " ";
        display: block;
        width: 30px;
        height: 30px;
        background-image: url(../img/plus.svg);
        background-size: cover;
        position: absolute;
        top: -1px;
        left: -45px; }
        @media only screen and (max-width: 576px) {
          .how__list li::before {
            left: -32px;
            width: 21px;
            height: 21px; } }
      .how__list li:not(:first-child) {
        margin-top: 10px; }
        @media only screen and (max-width: 576px) {
          .how__list li:not(:first-child) {
            margin-top: 7px; } }
    .how__list.minus li::before {
      background-image: url(../img/minus.svg); }

.description {
  padding: 100px 0 50px; }
  @media only screen and (max-width: 768px) {
    .description {
      padding: 50px 0 25px; } }
  .description__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-top: 60px; }
    @media only screen and (max-width: 1092px) {
      .description__row {
        -ms-flex-pack: distribute;
            justify-content: space-around; } }
    @media only screen and (max-width: 600px) {
      .description__row {
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between; } }
    @media only screen and (max-width: 576px) {
      .description__row {
        margin-top: 28px; } }
  .description__item {
    width: calc(33.3334% - 10px); }
    @media only screen and (max-width: 992px) {
      .description__item {
        width: calc(50% - 10px); } }
    .description__item .image-block img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      border-radius: 50%;
      margin: 0 auto; }
    .description__item .text {
      text-align: center;
      font-family: "PT Sans Regular";
      font-size: 20px;
      line-height: 30px;
      color: #404040;
      margin: 25px 0 30px; }
      @media only screen and (max-width: 480px) {
        .description__item .text {
          margin-top: 5px;
          font-size: 14px;
          line-height: 18px; } }
  @media only screen and (max-width: 576px) {
    .description .price__row {
      margin-top: 0; } }

.information {
  font-family: "PT Sans Regular";
  font-size: 18px;
  line-height: 29px;
  color: #404040;
  padding-bottom: 100px; }
  @media only screen and (max-width: 768px) {
    .information {
      font-size: 18px;
      line-height: 26px;
      padding: 25px 0; } }
  @media only screen and (max-width: 480px) {
    .information {
      padding: 25px 0;
      font-size: 15px;
      line-height: 20px; } }
  .information__title {
    margin-bottom: 50px !important; }
    @media only screen and (max-width: 768px) {
      .information__title {
        margin-bottom: 35px !important; } }
  @media only screen and (max-width: 768px) {
    .information .desctop {
      display: none; } }
  .information .mobile {
    display: none !important; }
    @media only screen and (max-width: 768px) {
      .information .mobile {
        display: block !important; } }

.look {
  padding: 40px 0; }
  @media only screen and (max-width: 480px) {
    .look {
      padding: 20px 0;
      margin-bottom: 0; } }
  .look__title {
    margin-bottom: 58px !important; }
    @media only screen and (max-width: 480px) {
      .look__title {
        margin-bottom: 41px !important; } }
  .look__video {
    width: 850px;
    margin: 0 auto;
    height: 480px; }
    @media only screen and (max-width: 900px) {
      .look__video {
        width: 100%; } }
    @media only screen and (max-width: 685px) {
      .look__video {
        height: 360px; } }
    @media only screen and (max-width: 525px) {
      .look__video {
        height: 205px; } }
    .look__video iframe {
      display: block;
      width: 100%;
      height: 100%; }
  .look .price__row {
    margin-top: 40px; }
    @media only screen and (max-width: 480px) {
      .look .price__row {
        margin-top: 28px; } }

.reviews {
  padding: 80px 0;
  background-color: #fff; }
  @media only screen and (max-width: 768px) {
    .reviews {
      padding: 60px 0; } }
  @media only screen and (max-width: 480px) {
    .reviews {
      padding: 35px 0; } }
  .reviews__slider--wrapper {
    position: relative; }
    @media only screen and (max-width: 1300px) {
      .reviews__slider--wrapper {
        padding: 0 45px; } }
    @media only screen and (max-width: 480px) {
      .reviews__slider--wrapper {
        padding: 0 20px; } }
    .reviews__slider--wrapper .reviews__buttons {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      left: -60px;
      width: calc(100% + 120px);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      z-index: 1; }
      @media only screen and (max-width: 1300px) {
        .reviews__slider--wrapper .reviews__buttons {
          left: -10px;
          width: calc(100% + 20px); } }
      @media only screen and (max-width: 480px) {
        .reviews__slider--wrapper .reviews__buttons {
          left: -5px;
          width: calc(100% + 10px); } }
      .reviews__slider--wrapper .reviews__buttons .reviews__buttons-prev, .reviews__slider--wrapper .reviews__buttons .reviews__buttons-next {
        width: 30px;
        height: 50px;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer; }
        @media only screen and (max-width: 480px) {
          .reviews__slider--wrapper .reviews__buttons .reviews__buttons-prev, .reviews__slider--wrapper .reviews__buttons .reviews__buttons-next {
            width: 15px;
            height: 25px; } }
      .reviews__slider--wrapper .reviews__buttons .reviews__buttons-prev {
        background-image: url(../img/arrow-left.svg); }
      .reviews__slider--wrapper .reviews__buttons .reviews__buttons-next {
        background-image: url(../img/arrow-right.svg); }
  .reviews__slider {
    z-index: 1; }
  .reviews .slick-list {
    margin: 0 -15px; }
  .reviews .slick-dots {
    bottom: -35px; }
    .reviews .slick-dots li {
      width: 15px;
      height: 15px;
      margin: 0 10px; }
      @media only screen and (max-width: 480px) {
        .reviews .slick-dots li {
          margin: 0 5px; } }
      .reviews .slick-dots li button {
        width: 15px;
        height: 15px; }
        .reviews .slick-dots li button::before {
          font-size: 15px;
          width: 15px;
          height: 15px;
          color: #F1F4FA;
          opacity: 1; }
          @media only screen and (max-width: 480px) {
            .reviews .slick-dots li button::before {
              font-size: 13px; } }
      .reviews .slick-dots li.slick-active button::before {
        color: #886BFC; }
  .reviews__slide {
    -webkit-box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
            box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-radius: 21px;
    margin: 100px 15px 20px;
    padding: 100px 0 40px;
    position: relative; }
    .reviews__slide .image-block {
      width: 200px;
      height: 200px;
      position: absolute;
      top: -100px;
      left: 50%;
      -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
              transform: translateX(-50%);
      overflow: hidden;
      border-radius: 50%; }
      .reviews__slide .image-block img {
        display: block;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover; }
    .reviews__slide .name, .reviews__slide .text {
      padding: 0 30px; }
      @media only screen and (max-width: 480px) {
        .reviews__slide .name, .reviews__slide .text {
          padding: 0 15px; } }
    .reviews__slide .name {
      margin-top: 35px;
      color: #172134;
      font-family: "PT Sans Bold";
      font-size: 28px;
      line-height: 40px;
      text-align: center; }
      @media only screen and (max-width: 480px) {
        .reviews__slide .name {
          margin-top: 15px;
          font-size: 24px;
          line-height: 36px; } }
    .reviews__slide .text {
      margin-top: 20px;
      font-family: "PT Sans Regular";
      font-size: 18px;
      line-height: 24px;
      color: #404040;
      text-align: center; }
      @media only screen and (max-width: 480px) {
        .reviews__slide .text {
          margin-top: 10px;
          font-size: 14px; } }
  .reviews .price__row {
    margin-top: 75px; }
  .reviews .price__row-block {
    border-color: #eaeaea;
    color: #606060; }
    .reviews .price__row-block span {
      color: #212121; }
    .reviews .price__row-block::after {
      background-color: #eaeaea; }

.characteristic {
  background-color: #fff;
  padding: 95px 0 25px; }
  @media only screen and (max-width: 480px) {
    .characteristic {
      padding: 50px 0; } }
  .characteristic__title {
    margin-bottom: 58px !important; }
    @media only screen and (max-width: 480px) {
      .characteristic__title {
        margin-bottom: 41px; } }
  .characteristic .list-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    @media only screen and (max-width: 991px) {
      .characteristic .list-row {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
  .characteristic__list {
    width: calc(50% - 20px);
    font-family: "PT Sans Regular";
    font-size: 18px;
    line-height: 22px;
    color: #404040; }
    @media only screen and (max-width: 991px) {
      .characteristic__list {
        width: 100%; } }
    @media only screen and (max-width: 480px) {
      .characteristic__list {
        font-size: 14px;
        line-height: 20px; } }
    .characteristic__list li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      padding: 4px 15px;
      background-color: #f0f3f8; }
      .characteristic__list li:nth-child(2n) {
        background-color: #fff; }
      .characteristic__list li p:first-child {
        width: 50%; }
      .characteristic__list li p:last-child {
        width: 50%;
        text-align: right; }
      .characteristic__list li.warning {
        color: #c73540; }
        .characteristic__list li.warning p:first-child {
          width: 0; }
        .characteristic__list li.warning p:last-child {
          width: 100%; }
  @media only screen and (max-width: 992px) {
    .characteristic .image-block {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: 100%;
      margin-top: 25px; } }
  @media only screen and (max-width: 480px) {
    .characteristic .image-block {
      margin-top: 15px; } }
  .characteristic .image-block img {
    max-width: 100%; }
  .characteristic .price__row {
    margin-top: 30px; }

.images__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.images__item {
  width: 33.33334%;
  max-height: 380px; }
  @media only screen and (max-width: 768px) {
    .images__item {
      width: 50%; } }
  @media only screen and (max-width: 576px) {
    .images__item {
      max-height: 266px; } }
  .images__item img {
    display: block;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    -o-object-fit: cover;
       object-fit: cover; }

.form {
  background-repeat: no-repeat;
  background-image: url(../img/form-bg.jpg);
  background-size: cover;
  background-color: #0b080d;
  color: #fff;
  padding: 35px 0;
  min-height: 768px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  z-index: 1; }
  @media only screen and (max-width: 888px) {
    .form {
      background-position-x: -140px; } }
  @media only screen and (max-width: 768px) {
    .form {
      background-position-x: -690px; } }
  @media only screen and (max-width: 576px) {
    .form {
      min-height: initial;
      background-color: #fff;
      background-image: none; } }
  .form::before {
    content: " ";
    display: block;
    width: 900px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), color-stop(50%, white), to(#FFFFFF));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 50%, #FFFFFF 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 50%, #FFFFFF 100%);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 50%, #FFFFFF 100%);
    z-index: -1; }
    @media only screen and (max-width: 1199px) {
      .form::before {
        display: none; } }
  .form .stamp {
    content: " ";
    display: block;
    width: 294px;
    height: 250px;
    background-image: url(../img/stamp.png);
    background-size: cover;
    position: absolute;
    top: 0;
    left: 45%;
    -webkit-transform: translateY(10px) translateX(-10px);
        -ms-transform: translateY(10px) translateX(-10px);
            transform: translateY(10px) translateX(-10px);
    opacity: 0;
    -webkit-transition: opacity .1s, -webkit-transform .1s;
    transition: opacity .1s, -webkit-transform .1s;
    -o-transition: opacity .1s, transform .1s;
    transition: opacity .1s, transform .1s;
    transition: opacity .1s, transform .1s, -webkit-transform .1s;
    -webkit-transition-delay: 2s;
         -o-transition-delay: 2s;
            transition-delay: 2s;
    z-index: 2; }
    @media only screen and (max-width: 1410px) {
      .form .stamp {
        left: 40%; } }
    @media only screen and (max-width: 1190px) {
      .form .stamp {
        left: 35%; } }
    @media only screen and (max-width: 1190px) {
      .form .stamp {
        left: 400px;
        top: 45px; } }
    @media only screen and (max-width: 1050px) {
      .form .stamp {
        left: 325px;
        top: 275px;
        width: 206px;
        height: 175px; } }
    @media only screen and (max-width: 900px) {
      .form .stamp {
        left: 425px;
        top: 0; } }
    @media only screen and (max-width: 888px) {
      .form .stamp {
        left: 325px; } }
    @media only screen and (max-width: 768px) {
      .form .stamp {
        top: -5px;
        left: 50%;
        -webkit-transform: translateX(-50%) !important;
            -ms-transform: translateX(-50%) !important;
                transform: translateX(-50%) !important; } }
    @media only screen and (max-width: 576px) {
      .form .stamp {
        width: 103px;
        height: 87.5px; } }
    @media only screen and (max-width: 480px) {
      .form .stamp {
        top: -20px; } }
    .form .stamp.active {
      -webkit-transform: translateY(0) translateX(0);
          -ms-transform: translateY(0) translateX(0);
              transform: translateY(0) translateX(0);
      opacity: 1; }
  .form__block {
    border-radius: 10px;
    -webkit-box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
            box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 35px 30px 30px;
    max-width: 360px;
    margin-left: auto; }
    @media only screen and (max-width: 768px) {
      .form__block {
        margin: 0 auto; } }
    @media only screen and (max-width: 480px) {
      .form__block {
        padding: 17.5px 15px 20px; } }
  .form__title {
    font-size: 32px !important;
    margin-bottom: 0 !important;
    line-height: 41px !important; }
    @media only screen and (max-width: 576px) {
      .form__title {
        font-size: 30px !important; } }
    @media only screen and (max-width: 350px) {
      .form__title {
        font-size: 28px !important; } }
    .form__title::after {
      display: none !important; }
  .form .container {
    position: relative;
    z-index: 1; }
  .form form {
    display: block; }
    .form form .input-wrapper {
      position: relative;
      width: 100%; }
      @media only screen and (max-width: 480px) {
        .form form .input-wrapper:last-child {
          margin-top: 15px; } }
    .form form .input-wrapper .floating-label {
      position: absolute;
      pointer-events: none;
      top: 30px;
      left: 0;
      -webkit-transition: 0.2s ease all;
      -o-transition: 0.2s ease all;
      transition: 0.2s ease all;
      font-family: "PT Sans Regular";
      font-size: 18px;
      color: #212121; }
      @media only screen and (max-width: 480px) {
        .form form .input-wrapper .floating-label {
          font-size: 14px;
          line-height: 38px; } }
    .form form .input-wrapper input:focus ~ .floating-label,
    .form form .input-wrapper input:not(:focus):valid ~ .floating-label {
      top: 0;
      font-size: 14px; }
    .form form .inputs-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      @media only screen and (max-width: 480px) {
        .form form .inputs-wrapper {
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; } }
    .form form input {
      display: block;
      width: 100%; }
      .form form input:not([type="submit"]) {
        height: 70px;
        background-color: transparent;
        border-bottom: 1px solid #212121;
        font-size: 25px;
        line-height: 40px;
        color: #212121;
        padding-top: 25px; }
        @media only screen and (max-width: 480px) {
          .form form input:not([type="submit"]) {
            padding-top: 15px;
            height: 75px; } }
      .form form input[type="submit"] {
        width: 260px;
        height: 70px;
        background-color: #886BFC;
        -webkit-box-shadow: 0px 4px 15px rgba(136, 107, 252, 0.4);
                box-shadow: 0px 4px 15px rgba(136, 107, 252, 0.4);
        border-radius: 73px;
        font-family: "PT Sans Regular";
        font-size: 20px;
        color: #fff;
        cursor: pointer;
        margin: 30px auto 0; }
        @media only screen and (max-width: 600px) {
          .form form input[type="submit"] {
            margin: 15px auto 0; } }
        @media only screen and (max-width: 341px) {
          .form form input[type="submit"] {
            max-width: 100%; } }
      .form form input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0; }
    .form form .color--wrapper {
      font-family: "PT Sans Regular";
      font-size: 18px;
      line-height: 40px;
      color: #212121;
      margin: 15px auto 0;
      text-align: center;
      max-width: 222px; }
      @media only screen and (max-width: 480px) {
        .form form .color--wrapper {
          font-size: 13px;
          line-height: 20px; } }
      .form form .color--wrapper div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        margin-top: 10px; }
      .form form .color--wrapper .color-item {
        display: block;
        width: 68px;
        height: 68px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        cursor: pointer; }
        .form form .color--wrapper .color-item:not(:first-child) {
          margin-left: 10px; }
        .form form .color--wrapper .color-item-name {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 13px;
          background-color: #fff;
          top: 0px;
          left: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
              -ms-transform: translateX(-50%) translateY(-50%);
                  transform: translateX(-50%) translateY(-50%);
          border: 1px solid #000;
          line-height: 14px;
          padding: 3px;
          border-radius: 10px;
          opacity: 0;
          -webkit-transition: opacity .3s;
          -o-transition: opacity .3s;
          transition: opacity .3s; }
          .form form .color--wrapper .color-item-name::after {
            content: " ";
            display: block;
            width: 7px;
            height: 7px;
            position: absolute;
            left: 50%;
            bottom: -5px;
            -webkit-transform: translateX(-50%) rotate(-45deg);
                -ms-transform: translateX(-50%) rotate(-45deg);
                    transform: translateX(-50%) rotate(-45deg);
            border-left: 1px solid #000;
            border-bottom: 1px solid #000;
            background-color: #fff; }
        .form form .color--wrapper .color-item:hover .color-item-name {
          opacity: 1; }
        @media only screen and (max-width: 480px) {
          .form form .color--wrapper .color-item {
            padding: 5px 0 5px 30px; } }
        @media only screen and (max-width: 480px) {
          .form form .color--wrapper .color-item {
            width: 50px;
            height: 50px; } }
        .form form .color--wrapper .color-item:first-child {
          background-color: #eaeaea; }
        .form form .color--wrapper .color-item:nth-child(2) {
          background-color: #3e3e3e; }
        .form form .color--wrapper .color-item input:checked ~ .radioBtn {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
              -ms-transform: translateX(-50%) translateY(-50%);
                  transform: translateX(-50%) translateY(-50%);
          width: 26px;
          height: 20px;
          background-image: url(../img/checked.svg);
          background-size: cover; }
  .form__price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-family: "PT Sans Regular";
    font-size: 16px;
    line-height: 24px;
    position: relative;
    left: -30px;
    color: #606060;
    width: calc(100% + 60px);
    padding: 15px 20px;
    text-align: center; }
    @media only screen and (max-width: 768px) {
      .form__price {
        margin: 20px auto 0; } }
    @media only screen and (max-width: 480px) {
      .form__price {
        font-size: 14px;
        line-height: 22px;
        left: -15px;
        width: calc(100% + 30px);
        padding: 10px 25px; } }
    .form__price .old span, .form__price .new span {
      font-size: 28px;
      line-height: 40px;
      display: inline-block;
      color: #181f2f;
      margin-top: 20px; }
      @media only screen and (max-width: 480px) {
        .form__price .old span, .form__price .new span {
          margin-top: 10px; } }
      @media only screen and (max-width: 374px) {
        .form__price .old span, .form__price .new span {
          font-size: 29px;
          line-height: initial; } }
    .form__price .new {
      text-align: right; }
      .form__price .new span {
        font-family: "PT Sans Bold"; }
        @media only screen and (max-width: 341px) {
          .form__price .new span {
            font-size: 26px; } }

.advantages {
  background-color: #2d3c5a;
  color: #fff;
  padding: 40px 0; }
  .advantages .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media only screen and (max-width: 768px) {
      .advantages .container {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
  .advantages__item {
    width: calc(33.33334% - 30px);
    padding-left: 120px;
    position: relative;
    background-repeat: no-repeat; }
    @media only screen and (max-width: 768px) {
      .advantages__item {
        width: 100%;
        padding-left: 90px; } }
    .advantages__item:first-child {
      background-image: url(../img/delivery-icon.svg);
      background-position: 20px 35px; }
      @media only screen and (max-width: 768px) {
        .advantages__item:first-child {
          background-position: 12px 23px;
          background-size: 55px; } }
    .advantages__item:nth-child(2) {
      background-image: url(../img/wallet-icon.svg);
      background-position: 27px 23px; }
      @media only screen and (max-width: 768px) {
        .advantages__item:nth-child(2) {
          background-position: 17px 13px;
          background-size: 44px;
          margin-top: 20px; } }
    .advantages__item:last-child {
      background-image: url(../img/package-icon.svg);
      background-position: 32px 28px; }
      @media only screen and (max-width: 768px) {
        .advantages__item:last-child {
          background-position: 20px 16px;
          margin-top: 20px; } }
    .advantages__item::before {
      content: " ";
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: solid 2px rgba(255, 255, 255, 0.4);
      position: absolute;
      left: 0;
      top: 0; }
      @media only screen and (max-width: 768px) {
        .advantages__item::before {
          width: 75px;
          height: 75px; } }
    .advantages__item-title {
      font-family: "PT Sans Bold";
      font-size: 24px;
      line-height: 40px; }
      @media only screen and (max-width: 480px) {
        .advantages__item-title {
          font-size: 21px;
          line-height: 38px; } }
    .advantages__item-description {
      margin-top: 10px;
      font-family: "PT Sans Regular";
      font-size: 18px;
      line-height: 22px; }
      @media only screen and (max-width: 480px) {
        .advantages__item-description {
          font-size: 14px;
          line-height: 20px; } }

.footer {
  background-color: #2d3c5a;
  padding: 15px 0;
  color: #fff;
  text-align: center;
  font-family: "PT Sans Regular";
  font-size: 16px;
  line-height: 22px; }
  .footer a {
    text-decoration: underline; }
    .footer a:hover {
      text-decoration: none; }
  .footer__contacts {
    color: #fff;
    opacity: .5;
    font-family: Arial;
    margin: 10px 0;
    font-weight: bold;
    font-size: 12px; }
    .footer__contacts a {
      text-decoration: none; }
      .footer__contacts a:hover {
        text-decoration: underline; }

body {
  overflow-x: hidden; }

.container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px; }

.section-title {
  font-family: "Futura PT Bold";
  font-size: 40px;
  line-height: 50px;
  color: #6F54DD;
  text-shadow: 0px 4px 10px rgba(136, 107, 252, 0.25);
  text-align: center;
  margin-bottom: 40px;
  position: relative; }
  .section-title::after {
    content: " ";
    display: block;
    width: 140px;
    height: 1px;
    background: #C4C4C4;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    bottom: -20px; }
  @media only screen and (max-width: 480px) {
    .section-title {
      font-size: 27px;
      line-height: 35px;
      margin-bottom: 35px; } }
  .section-title span {
    display: block;
    text-transform: uppercase; }

.price__row {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #F1F4FA;
  border-radius: 17px;
  padding: 35px 50px;
  margin: 60px 0 0;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%); }
  @media only screen and (max-width: 576px) {
    .price__row {
      padding: 25px 10px; } }
  @media only screen and (max-width: 601px) {
    .price__row {
      -ms-flex-pack: distribute;
          justify-content: space-around;
      width: 100%; } }
  @media only screen and (max-width: 375px) {
    .price__row {
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; } }
  .price__row-block {
    color: #000;
    display: inline-block; }
    .price__row-block .new {
      font-family: "PT Sans Regular";
      font-size: 16px;
      line-height: 22px; }
      .price__row-block .new span {
        display: block;
        margin-top: 10px;
        font-size: 30px;
        line-height: 38px;
        color: #000; }
  .price__row .price__order {
    display: inline-block; }
    .price__row .price__order span {
      display: inline-block;
      margin-left: 90px;
      padding: 21px 60px;
      color: #fff;
      font-family: "PT Sans Regular";
      font-size: 20px;
      line-height: 27px;
      background: #886BFC;
      -webkit-box-shadow: 0px 4px 15px rgba(136, 107, 252, 0.4);
              box-shadow: 0px 4px 15px rgba(136, 107, 252, 0.4);
      border-radius: 73px; }
      @media only screen and (max-width: 601px) {
        .price__row .price__order span {
          margin-left: 0; } }
      @media only screen and (max-width: 576px) {
        .price__row .price__order span {
          font-size: 20px;
          padding: 15px 10px; } }
      @media only screen and (max-width: 345px) {
        .price__row .price__order span {
          font-size: 18px; } }
