.header {
    background-repeat: no-repeat;
    background-image: url(../img/header-bg.jpg);
    background-color: #0B080D;
    background-position-x: right;
    background-size: cover;
    height: 768px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    z-index: 1;
    @include mediaw(992px) {
        background-position-x: calc(100% + 135px);
    }
    @include mediaw(768px) {
        background: #fffaf5;
        height: initial;
        padding-bottom: 20px;
    }
    &::before {
        content: " ";
        display: block;
        width: 900px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
        z-index: -1;
        @include mediaw(1199px) {
            width: 630px;
        }
        @include mediaw(768px) {
            display: none;
        }
    }
    .stamp {
        content: " ";
        display: block;
        width: 294px;
        height: 250px;
        background-image: url(../img/stamp.png);
        background-size: cover;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateY(10px) translateX(-10px);
        opacity: 0;
        transition: opacity .1s, transform .1s;
        transition-delay: 2s;
        z-index: 2;
        @include mediaw(1410px) {
            left: 40%;
        }
        @include mediaw(1190px) {
            left: 35%;
        }
        @include mediaw(1190px) {
            left: 400px;
            top: 45px;
        }
        @include mediaw(1075px) {
            left: 375px;
        }
        @include mediaw(1040px) {
            top: 95px;
            width: 206px;
            height: 175px;
        }
        @include mediaw(960px) {
            left: 330px;
        }
        @include mediaw(768px) {
            display: none;
        }
        &.active {
            transform: translateY(0) translateX(-0);
            opacity: 1;
        }
    }
    &__description {
        font-family: $fontPTSansRegular;
        font-size: 22px;
        line-height: 30px;
        color: #606060;
        @include mediaw(768px) {
            display: none;
        }
    }
    &__title {
        font-family: $fontFuturaBold;
        color: #6F54DD;
        text-shadow: 0px 4px 10px rgba(136, 107, 252, 0.25);
        margin-top: 30px;
        span {
            display: block;
        }
        .small {
            font-size: 24px;
            line-height: 31px;
            margin-top: 10px;
        }
        .medium {
            font-size: 29px;
            line-height: 37px;
        }
        .big {
            font-size: 52px;
            line-height: 67px;
        }
        @include mediaw(768px) {
            display: none;
        }
    }
    .image-block {
        display: none;
        width: calc(100% + 30px);
        transform: translateX(-15px);
        &.tablet {
            @include mediaw(768px) {
                display: block;
            }
        }
        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
    }
    &__list {
        margin-top: 30px;
        font-family: $fontPTSansRegular;
        font-size: 18px;
        line-height: 25px;
        color: #212121;
        @include mediaw(768px) {
            font-size: 14px;
            line-height: 38px;
            margin: 10px auto 0;
            max-width: 330px;
        }
        @include mediaw(370px) {
            font-size: 12px;
        }
        li {
            padding: 5px 0 5px 40px;
            background-repeat: no-repeat;
            background-position: left center;
            margin-top: 10px;
            @include mediaw(576px) {
                margin-top: 0;
            }
            &:first-child {
                background-image: url(../img/list-icon1.svg);
            }
            &:nth-child(2) {
                background-image: url(../img/list-icon2.svg);
            }
            &:last-child {
                background-image: url(../img/list-icon3.svg);
            }
        }
    }
    &__price {
        margin-top: 53px;
        padding: 30px;
        display: inline-block;
        font-family: $fontPTSansRegular;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #000;
        background: rgba(247, 245, 255, 0.6);
        border: 1px solid #E5DEFF;
        border-radius: 17px;
        @include mediaw(768px) {
            margin: 20px auto 0;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
        }
        @include mediaw(576px) {
            padding: 21px;
        }
        .new {
            span {
                font-family: $fontFuturaBook;
                font-size: 30px;
                line-height: 38px;
                transition: color .3s;
                @include mediaw(351px) {
                    font-size: 31px;
                }
            }
        }
    }
    &__order {
        margin-top: 20px;
        width: 260px;
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $fontPTSansRegular;
        font-size: 20px;
        line-height: 27px;
        color: #fff;background: #886BFC;
        box-shadow: 0px 4px 15px rgba(136, 107, 252, 0.4);
        border-radius: 73px;
    }
}